<template>
  <transition name="el-zoom-in-top">
    <router-view/>
  </transition>

</template>

<script>
export default {
  name: 'layoutMain'
}
</script>
