import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    breadcrumbList: [{ title: '首页', name: 'home' }],
    user: {
      id: null,
      name: '',
      account: '',
      ip: ''
    },
    isSuper: false,
    isDev: false,
    isTest: false,
    isSC: false,
    isPm: false,
    // roleList: [],
    // userList: [],
    categoryList: [],
    projectName: ''
  },
  getters: {},
  mutations: {
    SetUserStorage(state, user) {
      if (user.token) {
        localStorage.setItem('token', user.token)
        state.user = user
        const roleList = user.roleList
        const roles = []
        roleList.forEach(t=>{
          roles.push(t.abbr)
        })
        state.isSuper = roles.includes("admin");
        state.isDev = roles.includes("dev") || roles.includes("td");
        state.isTest = roles.includes("qa") || roles.includes("qd")
        state.isSC = roles.includes("pc");
        state.isPm = roles.includes("pm");
      }
    },
    setProjectName(state, val) {
      state.projectName = val
    },
    RemoveUserStorage() {
      localStorage.clear();
    },
    SetCategoryList(state, list) {
      if(list.length) {
        state.categoryList = list;
      }
    },
    SetCurrentRoute(state, route) {
      state.breadcrumbList = [{ title: '首页', name: 'home' }]
      if (!route.meta || !route.matched[0]) {
        return null
      }
      route.matched.map((item, index) => {
        if (index) {
          state.breadcrumbList.push({
            title: item.meta?.title,
            name: item.name || item.redirect?.name,
            isHidden: item.meta?.isHidden || false
          })
        }
      })
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()]
})
