import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
let showMsg = true;
const service = axios.create({
  baseURL: null,
  timeout: 600000
})

service.interceptors.request.use(
  (config) => {
    config.headers.token = localStorage.getItem('token')
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const { code, message, state } = response.data
    // Do something before response is sent
    if (code === 200 && state) {
      return response.data
    } else if (code === 401) {
      localStorage.clear();
      window.location = '/#/login'
      if (showMsg) {
        Message.error(message)
        showMsg = false
        setTimeout(() => {
          showMsg = true;
        }, 3000);
      }
    } else {
      Message.error(message)
      return response.data
    }
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error)
  }
)
export default service
