<template>
  <el-container direction="vertical" style="position: relative;width: 100%; height: 100vh;">
    <JHeader />
    <el-container direction="horizontal">
      <JMenus />
      <el-container direction="vertical">
        <JBreadcrumb />
        <el-container direction="vertical">
          <transition name="el-zoom-in-top">
            <router-view style="overflow-y: scroll;"/>
          </transition>
        </el-container>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'layoutApp',
  components: {
    JHeader: () => import('@/components/Layout/JHeader'),
    JMenus: () => import('@/components/Layout/JMenus'),
    JBreadcrumb: () => import('@/components/Layout/JBreadcrumb')
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler() {
        this.$store.commit('SetCurrentRoute', this.$route)
        // if (!this.user || !this.user.id) {
        //   this.$store.commit('RemoveUserStorage');
        //   this.$router.push({ name: 'login'})
        // } else {
        //   this.$store.commit('SetCurrentRoute', this.$route)
        // }
      }
    }
  }
}
</script>
